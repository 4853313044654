import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import { Title } from '@mui/icons-material';
import DashboardCard from 'components/DashboardCard';
import api from 'api';



const NameAndLogo = () => {
	const [ loaded, setLoaded ] = useState(false);
	const [ clinicData, setClinicData ] = useState({ name: '', logo: null });

	useEffect(() => {
		api.get('/clinic-settings/name-logo')
			.then(res => {
				setClinicData({
					name: res.data?.clinic?.name || '',
					logo: res.data?.clinic?.logo || null,
				});
			})
			.catch(err => console.error(err))
			.finally(() => setLoaded(true));
	}, []);

	return (
		<DashboardCard
			color={'orange'}
			icon={<Title />}
			topContent={"Clinic Name and Logo"}
			size="xl"
		>
			<Grid container spacing={2} sx={{ marginTop: 1 }}>
				<Grid item xs={12} lg={6}>
					<Typography variant="body2">
						Please enter the name of your clinic as you would like it to appear on reports and in
						communication with our support team.
					</Typography>
					<TextField
						placeholder="Enter the name of your clinic."
						label="Clinic name"
						value={clinicData.name}
					/>
					<Button variant="contained" sx={{ marginLeft: 2 }}>Save</Button>
				</Grid>
				<Grid item xs={12} lg={6}>
					<Typography variant="body2">
						Click in the box below, or drag and drop an image to upload your clinic logo. Uploading
						a logo allows its use in reports.
					</Typography>
					<Box
						sx={{
							maxWidth: '400px',
							maxHeight: '300px',
							border: '3px dashed #858585',
							borderRadius: '12px',
						}}
					>
						{!!clinicData.logo &&
							<img
								src={clinicData.logo}
								style={{ maxWidth: '380px', maxHeight: '280px', paddingLeft: '10px' }}
							/>}
					</Box>
				</Grid>
			</Grid>

		</DashboardCard>
	);
};


export default NameAndLogo;
