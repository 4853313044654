import React, { useEffect, useState } from 'react';
import { Button, Grid, TextField, Typography } from '@mui/material';
import { Place } from '@mui/icons-material';
import DashboardCard from 'components/DashboardCard';
import { Link } from 'react-router-dom';
import api from 'api';



const ContactInfo = () => {
	const [ loaded, setLoaded ] = useState(false);
	const [ clinicData, setClinicData ] = useState({
		address: {
			address_id: null,
			address_name: '',
			address_line_1: '',
			address_line_2: '',
			address_city: '',
			address_state: '',
			address_country: '',
			address_postal_code: '',
		},
		contact: {
			cm_id: null,
			cm_name: '',
			cm_value: '',
			cm_type: 'email',
			cm_note: '',
		},
	});

	const { address, contact } = clinicData;


	useEffect(() => {
		api.get('/clinic-settings/address-contact-info')
			.then(res => {
				console.log(res.data);
				setClinicData({
					address: res.data?.clinic?.address || {},
					contact: res.data?.clinic?.contact || {},
				});
			})
			.catch(err => console.error(err))
			.finally(() => setLoaded(true));
	}, []);


	return (
		<DashboardCard
			color={'orange'}
			icon={<Place />}
			topContent={"Address and Contact Info"}
			size="xl"
		>
			<Grid container spacing={2} sx={{ marginTop: 1 }}>
				<Grid item xs={12} lg={6}>
					<Typography variant="body1">Address</Typography>
					<Typography variant="body2">
						Please enter the address of your clinic below. This address is able to be included in reports.
					</Typography>
					<Grid item xs={12} sx={{ marginBottom: 1 }}>
						<TextField
							placeholder="Address Line 1"
							label="Address Line 1"
							sx={{ width: '428px' }}
							value={address.address_line_1 || ''}
						/>
					</Grid>
					<Grid item xs={12} sx={{ marginBottom: 1 }}>
						<TextField
							placeholder="Address Line 2"
							label="Address Line 2"
							sx={{ width: '428px' }}
							value={address.address_line_2 || ''}
						/>
					</Grid>
					<Grid item xs={12} sx={{ marginBottom: 1 }}>
						<TextField
							placeholder="City"
							label="City"
							sx={{ marginRight: 1 }}
							value={address.address_city || ''}
						/>
						<TextField
							placeholder="State/Province"
							label="State/Province"
							value={address.address_state || ''}
						/>
					</Grid>
					<Grid item xs={12} sx={{ marginBottom: 1 }}>
						<TextField
							placeholder="ZIP/Postal Code"
							label="ZIP/Postal Code"
							sx={{ marginRight: 1 }}
							value={address.address_postal_code || ''}
						/>
						<TextField placeholder="Country" label="Country" value={address.address_country || ''} />
					</Grid>

				</Grid>

				<Grid item xs={12} lg={6}>
					<Typography variant="body1">Phone Number</Typography>
					<Typography variant="body2">
						Please enter the phone number for your clinic. This number is able to be included in reports.
					</Typography>
					<TextField placeholder="Enter your clinic's phone number" label="Phone number" />
				</Grid>

				<Grid item xs={12} lg={6} sx={{ marginTop: 2 }}>
					<Typography variant="body1">Email Address</Typography>
					<Typography variant="body2">
						Please enter the email address for your clinic. This email address is able to be included in
						reports.<br />
						Visit the <Link to="/clinic-settings/billing">billing settings</Link> page to set a billing
						email address.
					</Typography>
					<TextField
						placeholder="Enter your clinic's email address"
						label="Email address"
						sx={{ width: '428px' }}
					/>
				</Grid>

				<Grid item xs={12} sx={{ marginTop: 2 }}>
					<Button variant="contained">Save Contact Details</Button>
				</Grid>
			</Grid>

		</DashboardCard>
	);
};

export default ContactInfo;
